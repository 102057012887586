export const speaker = [
  {
    title: "Our Team_",
    persons: [
      {
        firstName: "Amarbayar",
        lastName: "Amarsanaa",
        role: " Co-Chair",
        work: "DevSummit",
        picture: "amaraa",
      },
      {
        firstName: "Undral",
        lastName: "Amarsaikhan",
        role: "Co-founder",
        work: "Unread",
        picture: "undral",
      },
      {
        firstName: "Ganzorig",
        lastName: "Dolingor",
        role: "CEO",
        work: "Unread",
        picture: "ganzorig",
      },
      {
        firstName: "Munkhbayasgalan",
        lastName: "Delgerbat",
        role: "Former general manager",
        work: "Unread",
        picture: "baysa",
      },
      {
        firstName: "Achmaa",
        lastName: "Ariunbold",
        role: "Graphich Designer",
        work: "Unread",
        picture: "achmaa",
      },
      {
        firstName: "Munkhzul",
        lastName: "Boldbaatar",
        role: "Ambassador",
        work: "WTM Mongolia",
        picture: "munkhzul",
      },
      {
        firstName: "Khongorzul",
        lastName: "Munkhbat",
        role: "Ambassador",
        work: "WTM Mongolia",
        picture: "khongorzul",
      },
      {
        firstName: "Enerel",
        lastName: "Enkhtaivan",
        role: "Partnership Manager",
        work: "Unread",
        picture: "enerel",
      },
      {
        firstName: "Munkh-Ami ",
        lastName: "Batjargal",
        role: "Video Creator",
        work: "Unread",
        picture: "munkh-ami",
      },
      {
        firstName: "Sumiyabazar",
        lastName: "Amarjargal",
        role: "Web developer",
        work: "Unread",
        picture: "sumiya",
      },
    ],
  },
  {
    title: "Women Techmakers_",
    persons: [
      {
        firstName: "Urandelger",
        lastName: "Ochirkhuyag",
        role: "CTO",
        work: "SmartData",
        picture: "urandelger",
      },
      {
        firstName: "Enkhjin",
        lastName: "Otgonbayar",
        role: "CEO",
        work: "tomyo.io",
        picture: "Enkhjin",
      },
      {
        firstName: "Naran",
        lastName: "Batjargal",
        role: "Co-founder/COO",
        work: "Erxes Inc",
        picture: "Naran Batjargal",
      },
      {
        firstName: "Bolortuya",
        lastName: "Bayantur",
        role: "Data Quality Analyst",
        work: "Rio Tinto",
        picture: "Bolortuya_Bayantur",
      },
      {
        firstName: "Enerel",
        lastName: "Tumenbayar",
        role: "CFO",
        work: "TechPack LLC",
        picture: "EnerelSpeaker",
      },
      {
        firstName: "Deglee",
        lastName: "Ganzorig",
        role: "Graphic designer",
        work: "Central TV",
        picture: "Deglee",
      },
      {
        firstName: "Misheel",
        lastName: "Batbuyan",
        role: "Student",
        work: "Nest Academy",
        picture: "misheel",
      },
      {
        firstName: "Anu-Ujin",
        lastName: "Bat-Ulzii",
        role: "Sr Front-end developer",
        work: "erxes",
        picture: "Anu-Ujin",
      },
      {
        firstName: "Eja",
        lastName: "Batbold",
        role: "",
        work: "Tomyo edtech",
        picture: "Eja Batbold",
      },
      {
        firstName: "Enkhtuya",
        lastName: "Dandar",
        role: "Director/Principal Auditor",
        work: " Information Technology Center",
        picture: "Enkhtuya",
      },
      {
        firstName: "Uzmee",
        lastName: "Battsagaan",
        role: "Business Analyst",
        work: "Interactive LLC",
        picture: "Uzmee",
      },
      {
        firstName: "Suvdaa",
        lastName: "Batsuuri",
        role: "Associate Professor",
        work: "National University of Mongolia",
        picture: "Suvdaa",
      },
      {
        firstName: "Tuvshinzaya",
        lastName: "Erdenebat",
        role: "Guest moderator/ UX Designer ",
        work: "DevSummit/ MPlus LLc",
        picture: "Tuvshinzaya",
      }, {
        firstName: "Dulgoonbayar",
        lastName: "Mungunzagas",
        role: "Guest moderator/ Founder/ Sr UX Designer",
        work: "DevSummit/ UX Academy/ Mezorn Technology",
        picture: "Dulgoon",
      },
      {
        firstName: "Munkhtsetseg",
        lastName: "Baasandorj",
        role: "CEO",
        work: "GrapeCity Mongolia LLC",
        picture: "Munhtsetseg",
      },
      {
        firstName: "Otgonjargal",
        lastName: "Myagmar",
        role: "Project Manager",
        work: "Datacare LLC",
        picture: "Otgoo",
      },
    ],
  },
  {
    title: "Panel Discussions_",
    persons: [
      {
        firstName: "Munkhtsetseg",
        lastName: "Baasandorj",
        role: "CEO",
        work: "GrapeCity Mongolia LLC",
        picture: "Munhtsetseg",
      },
      {
        firstName: "Munkhjargal",
        lastName: "N",
        role: "Digital bank director",
        work: "Capitron bank",
        picture: "Munkhjargal",
      },
      {
        firstName: "Tulga",
        lastName: "Gandavaa",
        role: "Founder",
        work: "CallPro, LIME",
        picture: "Tulga",
      },
      {
        firstName: "Erdenetuya",
        lastName: "Chuluunbaatar",
        role: "COO",
        work: "Andorean",
        picture: "Erdenetuya",
      },
      {
        firstName: "Enkhjin",
        lastName: "Otgonbayar",
        role: "CEO",
        work: "tomyo.io",
        picture: "Enkhjin",
      },
      {
        firstName: "Naran",
        lastName: "Batjargal",
        role: "Co-founder/COO",
        work: "Erxes Inc",
        picture: "Naran Batjargal",
      },
      {
        firstName: "Munkhdalai",
        lastName: "Batjargal",
        role: "CEO",
        work: "Nito",
        picture: "Munhdalai",
      },
      {
        firstName: "Bolortuya",
        lastName: "Bayantur",
        role: "Data Quality Analyst",
        work: "Rio Tinto",
        picture: "Bolortuya_Bayantur",
      },
      {
        firstName: "Deglee",
        lastName: "Ganzorig",
        role: "Graphic designer",
        work: "Central TV",
        picture: "Deglee",
      },
      {
        firstName: "Misheel",
        lastName: "Batbuyan",
        role: "Student",
        work: "Nest Academy",
        picture: "misheel",
      },
      {
        firstName: "Anu-Ujin",
        lastName: "Bat-Ulzii",
        role: "Sr Front-end developer",
        work: "erxes",
        picture: "Anu-Ujin",
      },
      {
        firstName: "Eja",
        lastName: "Batbold",
        role: "",
        work: "Tomyo edtech",
        picture: "Eja Batbold",
      },
      {
        firstName: "Enkhtuya",
        lastName: "Dandar",
        role: "Director/Principal Auditor",
        work: " Information Technology Center",
        picture: "Enkhtuya",
      },
    ],
  },
  {
    title: "Workshops_",
    persons: [
      {
        firstName: "Sarangerel",
        lastName: "",
        role: "Lead Data Scientist",
        work: "And Systems Tech LLC",
        picture: "Sarangerel",
      },
      {
        firstName: "Amarsaikhan",
        lastName: "",
        role: "ML engineer",
        work: "And Systems Tech LLC",
        picture: "Amarsaikhan",
      },
      {
        firstName: "Amin-Erdene",
        lastName: "",
        role: "Art Director",
        work: "MetaMori NFT",
        picture: "Amin-erdene",
      },

      {
        firstName: "Tushigt-Erdene",
        lastName: "",
        role: "CIO",
        work: "MetaMori NFT",
        picture: "Tushigt-erdene",
      },

      {
        firstName: "Tugsjin",
        lastName: "",
        role: "CTO",
        work: "MetaMori NFT",
        picture: "Tugsjin",
      },
      // {
      //   firstName: "Amartuvshin",
      //   lastName: "",
      //   role: "Senior Hardware Engineer",
      //   work: "Andorean",
      //   picture: "Amartuvshin",
      // },
    ],
  },
  {
    title: "Tech Talks_",
    persons: [
      {
        firstName: "Naranbat",
        lastName: "Jargalsaikhan",
        role: "CTO",
        work: "Denode",
        picture: "Naranbat",
      },
      {
        firstName: "Bill",
        lastName: "Dashdorj",
        role: "CEO",
        work: "Denode",
        picture: "Bill",
      },
      {
        firstName: "Erdenezaya",
        lastName: "Batnasan",
        role: "Manager of IT Automation Engineering",
        work: "Khan Bank",
        picture: "Sarantuya",
      },
      {
        firstName: "Bayasgalan",
        lastName: "T",
        role: "Information security administrator",
        work: "Khan Bank",
        picture: "Bayasgalan",
      },
      {
        firstName: "Magvanshiirev",
        lastName: "Janchiv",
        role: "Manager",
        work: "Mobifinance BBSB",
        picture: "Magvanshiirev",
      },
      {
        firstName: "Usukhbayar",
        lastName: "Ganbaatar",
        role: "Head of Mobile Developmen",
        work: "Mezorn",
        picture: "Usukhbayar_Ganbaatar",
      },
      {
        firstName: "Buyantugs",
        lastName: "Luvsankhuu",
        role: "Director of Product Development Department",
        work: "Mobicom",
        picture: "Buyantugs",
      },
      {
        firstName: "Anu",
        lastName: "Otgonjargal",
        role: "Front-end Developer",
        work: "Meta Mori  LLC",
        picture: "Anu Otonjargal",
      },
      {
        firstName: "Ariunaa",
        lastName: "Ganbat",
        role: "UX / UI designer",
        work: "And Systems Tech",
        picture: "ariuna",
      },
      {
        topic: "Recommendation System",
        firstName: "Tungalag",
        lastName: "Ziinaa",
        role: "Data Scientist",
        work: "Khan Bank",
        picture: "Tungalag_Ziinaa",
      },
      {
        firstName: "Khongorzul",
        lastName: "Munkhbat",
        role: "ML Engineer",
        work: "MMusic",
        picture: "Khongorzul_Munkhbat",
      },
      {
        firstName: "Tumur-Ochir",
        lastName: "Nasanjargal",
        role: "Senior ML Engineer",
        work: "And Systems Tech LLC",
        picture: "Tumur-Ochir_Nasanjargal",
      },
      {
        firstName: "Munkh-Od",
        lastName: "Ganzorigt",
        role: "CTO",
        work: "DiverseSolutions",
        picture: "Munkh-Od_Ganzorigt",
      },
      {
        firstName: "Zolboobayar",
        lastName: "Gantumur",
        role: "Tech Lead",
        work: "Meta Global Inc LLC",
        picture: "Zolboobayar_Gantumur",
      },
      {
        firstName: "Tuguldur",
        lastName: "Tugsuu",
        role: "Hardware Engineer",
        work: "Andorean",
        picture: "Tuguldur",
      },
      {
        firstName: "Tuvshintsenguun",
        lastName: "Erdenejargal",
        role: "Software Test Engineer",
        work: "Input Output Global",
        picture: "Tuvshintsenguun_Erdenejargal",
      },
      {
        firstName: "Ganbayar",
        lastName: "Uuganbayar",
        role: "Cyber security researcher",
        work: "University of Oxford",
        picture: "ganbayar",
      },
      {
        firstName: "Tengis",
        lastName: "Sukhee",
        role: "Information security advisor",
        work: "Indepenent",
        picture: "Tengis_Sukhee",
      },
      {
        firstName: "Bilguun",
        lastName: "Bayarmagnai",
        role: "CTO",
        work: "Nomadays LLC",
        picture: "Bilguun_Bayarmagnai",
      },
      {
        firstName: "Bilguun",
        lastName: "Bayarsaikhan",
        role: "Senior Software Engineer",
        work: "Unimedia Solutions",
        picture: "Bilguun_Bayarsaikhan",
      },
      {
        firstName: "Dulmandakh",
        lastName: "Sukhbaatar",
        role: "Founder / CEO",
        work: "Khuur Music Group LLC",
        picture: "Dulmandakh_Sukhbaatar",
      },
      {
        firstName: "Lkhavuujal",
        lastName: "Nipra",
        role: "Director of DevOps Division",
        work: "Grapecity Mongolia LLC",
        picture: "Lkhavuujal_Nipra",
      },
      {
        firstName: "Dagvasuren",
        lastName: "Batbold",
        role: "Founder",
        work: "Beelog Tech",
        picture: "Davgasuren_Batbold",
      },
      {
        firstName: "BatAmar",
        lastName: "Battulga",
        role: "CTO",
        work: "Erxes",
        picture: "BatAmar_Battulga",
      },
      {
        firstName: "Munkh-Abdul",
        lastName: "Mikael",
        role: "CTO",
        work: "Clinica",
        picture: "Munkh-Abdul_Mikael",
      },
      {
        firstName: "Matus",
        lastName: "Faro",
        role: "Architecture Lead",
        work: "AND Global",
        picture: "Matus_Faro",
      },
      {
        firstName: "Erkhembayar",
        lastName: "Gantulga",
        role: "CTO",
        work: "TomYo EdTech",
        picture: "Erkhembayar_Gantulga",
      },
      {
        firstName: "Monhoo",
        lastName: "Batraa",
        role: "Indie Game Developer",
        work: "Alpha games",
        picture: "monhoo",
      },
      {
        firstName: "Batzorig",
        lastName: "Byambabaatar",
        role: "VR Dev / PM",
        work: "Digital Solutions Mongolia",
        picture: "batzorig",
      },
      {
        firstName: "Namuun",
        lastName: "Bayarsaikhan",
        role: "VR Dev / PM",
        work: "Digital Solutions Mongolia",
        picture: "namuun",
      },
    ],
  },
]
export const events = {
  t: [
    {
      name: "Doors open / morning coffee / check-in",
      description: "Doors open / morning coffee / check-in ",
      startTime: "8:00",
      endTime: "9:10",
    },
    {
      name: "Meditation",
      description: "Let's meditate!",
      startTime: "8:30",
      endTime: "9:10",
    },
    {
      name: "Amarbayar, Co-Chair of DevSummit",
      description: "Opening",
      startTime: "9:00",
      endTime: "9:10",
    },
    {
      type: "topic",
      name: "Frontend",
    },
    {
      name: "Zolboobayar, Tech Lead @ Meta Global Inc LLC",
      description: "Android + Kotlin: Jetpack Compose experience",
      startTime: "9:12",
      endTime: "9:20",
    },
    {
      name: "Usukhbayar, Head of Mobile Developmen @ Mezorn",
      description: "Native to Hybrid, Hybrid development",
      startTime: "9:22",
      endTime: "9:30",
    },
    {
      name: "TBD",
      description: "TBD",
      startTime: "9:32",
      endTime: "12:00",
    },
    {
      name: "Anu, Front-end Developer @Meta Mori  LLC",
      description: "Web Development",
      startTime: "9:50",
      endTime: "14:00",
    },
    {
      name: "Ariunaa, UX / UI designer @ And Systems Tech",
      description: "Importance of Design System",
      startTime: "10:00",
      endTime: "15:00",
    },

    {
      name: "Break",
      description: "Break time :)",
      startTime: "10:08",
      endTime: "17:00",
    },

    {
      type: "topic",
      name: "AI/ML/DS",
    },
    {
      name: "Luvsanbat, Data Scientist @ ITC.GOV",
      description: "Supervised classification use-case on eBarimt",
      startTime: "10:15",
      endTime: "17:00",
    },

    {
      name: "Tungalag, Data Scientist @ Khan Bank",
      description: "Recommendation System",
      startTime: "10:25",
      endTime: "20: 00",
    },
    {
      name: "Khongorzul, ML Engineer @ MMusic",
      description: "What is Music and AI? How AI/ML composes music?",
      startTime: "10:35",
      endTime: "20: 00",
    },
    {
      name: "Tumur-Ochir, Senior ML Engineer@ And Systems Tech LLC",
      description: "MLOps (CI/CD/CT for Model Development) ",
      startTime: "10:53",
      endTime: "20: 00",
    },
    {
      name: "Break",
      description: "Break time :)",
      startTime: "11:01",
      endTime: "20: 00",
    },
    {
      name: "• Bayasgalan. T, Information security administrator @ Khan Bank \n• Erdenezaya. B, Manager of IT Automation Engineering @ Khan Bank",
      description: "• 1st part: API Modernization \n • 2nd part: Ensuring Security for digital transformation",
      startTime: "11:13",
      endTime: "20: 00",
    },
    {
      type: "topic",
      name: "Blockchain",
    },
    {
      name: "Munkh-Od, CTO @ DiverseSolutions",
      description: "Building Decentralized Applications (dapp)",
      startTime: "11:35",
    },
    {
      name: "Tuvshintsenguun, Software Test Engineer @ Input Output Global",
      description: "Architecting DApps on the UTXO Ledger",
      startTime: "11:53",
    },

    {
      type: "topic",
      name: "Testing",
    },
    {
      name: "Bilguun, Senior Software Engineer @ Unimedia Solutions",
      description:
        "Web app & Browser extension integration test (Selenium WebDriver + Puppeteer)",
      startTime: "12:11",
    },
    {
      name: "Lunch Break",
      description: "Break Time :)",
      startTime: "12:27",
    },
    {
      name: "• Bayarmagnai, CTO @ Ard Financial Group \n• Enkhamgalan, CEO @ Diverse Solutions",
      description:
        "• 1st part: START-UPS SYNERGY \n• 2nd part: Blockchain and Diverse",
      startTime: "13:10",
    },
    {
      type: "topic",
      name: "Infrastructure",
    },
    {
      name: "Dulmandakh, Founder / CEO @ Khuur Music Group LLC",
      description: "CI/CD",
      startTime: "13:32",
    },
    {
      name: "Lkhavuujal, Director of DevOps Division @ Grapecity Mongolia LLC",
      description: "Observability: Logging, Alerting, Monitoring",
      startTime: "13:50",
    },
    {
      name: "Break",
      description: "Break Time :)",
      startTime: "14:06",
    },
    {
      name: "Magvanshiirev, Manager @ Mobifinance BBSB",
      description: "Mobicom - Lightning Talk",
      startTime: "14:13",
    },

    {
      type: "topic",
      name: "Backend",
    },
    {
      name: "Bilguun, CTO@ Nomadays LLC",
      description: "Migrating microservices (Java, NodeJS) into Kubernetes",
      startTime: "14:25",
    },
    {
      type: "topic",
      name: "Security / Privacy / Compliance",
    },

    {
      name: "Tengis, Information security advisor ",
      description:
        "New Laws / Regulations in Mongolia about PII, Cybersecurity, Opendata",
      startTime: "14:35",
    },
    {
      name: "Ganbayar, Cyber security researcher  @ University of Oxford ",
      description: "Effectiveness of Security Controls",
      startTime: "14:53",
    },

    {
      name: "• Naranbat. J, CTO @Denode\n• Bill CEO @Denode",
      description: "AndGlobal - Web3, Crypto, Going Global",
      startTime: "15:11",
    },
    {
      name: "Break",
      description: "Break Time :)",
      startTime: "15:31",
    },

    {
      type: "topic",
      name: "Gaming / AR / VR",
    },
    {
      name: "Monhoo, Indie Game Dev Developer @ Alpha Games",
      description: "Indie game developer and his first game",
      startTime: "15:38",
    },
    {
      name: "Namuun, PM / VR Dev @ Digital Solutions Mongolia",
      description: "Reliving History in VR",
      startTime: "15:48",
    },
    {
      name: "Batzorig, PM / VR Dev @ Digital Solutions Mongolia",
      description: "Integrating with Unity / Unreal engines",
      startTime: "15:58",
    },
    {
      name: "Tuguldur, Hardware Engineer @ Andorean",
      description: "How to integrate Metaverse and real-life",
      startTime: "16:08",
    },
    {
      name: "Partner Sponsor 2 - Lightning Talk ",
      description: "TENTATIVE: Metaverse ",
      startTime: "16:22",
    },
    {
      name: "Coffee Break",
      description: "Break Time :)",
      startTime: "16:32",
    },

    {
      type: "topic",
      name: "Architecture",
    },
    {
      name: "Buyantugs, Director of Product Development Department @ Mobicom",
      description: "Throttle architecture pattern",
      startTime: "16:44",
    },
    {
      name: "Davgasuren, Founder @ Beelog Tech",
      description: "Serverless IoT Architecture",
      startTime: "16:54",
    },
    {
      name: "TBD",
      description: "TBD",
      startTime: "17:12",
    },
    {
      name: "BatAmar, CTO @ Erxes",
      description: "Plugin architecture",
      startTime: "17:30",
    },
    {
      name: "Munkh-Abdul, CTO @ Clinica",
      description: "Architecting for video call based telemed app",
      startTime: "17:48",
    },
    {
      name: "Erkhembayar, CTO @ TomYo EdTech",
      description:
        "TomYo.io - is a drag-and-drop app builder for educational content creators",
      startTime: "18:06",
    },
    {
      name: "TBD",
      description: "TBD",
      startTime: "18:24",
    },
    {
      name: "Matus, Architecture Lead @ AND Global",
      description:
        "Stream processing data in real-time using Apache Kafka and Apache Samza",
      startTime: "18:42",
    },
    {
      name: "• Munkhjargal - Head of Digital Transformation @Capitron bank\n• Tulga - CEO @CallPro, LIME \n• Munkhdalai - CEO @Nito\n• Erdenetuya - COO @Andorean",
      description: "Panel Discussion by Unread",
      startTime: "19:00",
    },
    {
      name: "CLOSING",
      description: "",
      startTime: "19:40",
    },
  ],
  w: [
    {
      name: "Otgonjargal, Sr Project Manager @Datacare LLC",
      description: "Project Management",
      startTime: "9:30",
      endTime: "11:00",
    },
    {
      name: "Suvdaa, Associate Professor @ National University of Mongolia",
      description: "Computer Vision in Mongolia: Now and Future",
      startTime: "10:00",
      endTime: "11:00",
    },

    {
      name: "Panel: Career & Job",
      description:
        "Deglee, Graphic Designer @Central TV \n Bolortuya, Data Quality Analyst @Rio Tinto \n  Misheel, Student @Nest Academy \n Anu-Ujin, Senior Front-end developer @Erxes Inc",
      startTime: "11:40",
      endTime: "20: 00",
    },
    {
      name: "Uzmee, Business Analyst @Interactive LLC",
      description: "Anyone can be a Techmaker",
      startTime: "13:30",
      endTime: "17:00",
    },
    {
      name: "Naran, Co-founder/COO @Erxes Inc",
      description: "Start-up, then Scale-up",
      startTime: "13:50",
      endTime: "17:00",
    },
    {
      name: "• Enkhtuya, Director of Information Technology Center/Principal Auditor \n • Munkhtsetseg, CEO @Grapecity LLC \n• Nomin U, Individual ICT Consultant / Red hat instructor @ADB funded project / NUM Cisco Academy",
      description: "Women leadership in IT",
      startTime: "14:20",
    },
    {
      name: "Urandelger, CTO @SmartData",
      description: "Spatial and location-based information system",
      startTime: "15:30",
      endTime: "17:40",
    },
    {
      name: "Enerel, CFO @TechPack LLC",
      description: "MCS Software House",
      startTime: "16:35",
      endTime: "17:40",
    },
    {
      name: "• Enkhjargal, CEO/Founder @tomyo.io \n• Naran, Co-founder/COO @Erxes Inc",
      description: "Panel: Global Fundraising",
      startTime: "16:55",
    },
    // {
    //   name: "Biligjargal, Marketing Manager @Mmusic LLC",
    //   description:
    //     "",
    //   startTime: "18:00",
    // }
  ],
  wo: [
    {
      name: "• Sarangerel, Lead Data Scientist \n • Amarsaikhan, ML engineer @And Systems Tech LLC",
      description: "[Workshop] AutoML - Niko Scoring",
      startTime: "10:20",
    },
    {
      name: "• Tushigt-Erdene, CIO @MetaMori NFT\n • Tugsjin, CTO @MetaMori NFT\n• Amin-Erdene, Art Director @MetaMori NFT",
      description: "How to build NFT?",
      startTime: "15:50",
    },
    {
      name: "Battogtokh, Senior manager @Capitron bank",
      description: "On cyber security",
      startTime: "17:45",
    },
    {
      name: "Munkh-Od, CTO @ DiverseSolutions",
      description: "NFT Marketplace Smart Contracts",
      startTime: "18:10",
    },
  ],
  a: [
    {
      name: "• Zolboobayar, Tech Lead @ Meta Global Inc LLC \n • Usukhbayar,Head of Mobile Developmen @ Mezorn \n • Anu, Front-end Developer @ Meta Mori LLC \n• Ariunaa, UX / UI designer @ And Systems Tech",
      description: "Ask Me Anything",
      startTime: "10:40",
    },
    {
      name: "• Tungalag, Data Scientist @ Khan Bank\n• Khongorzul, ML Engineer @ MMusic \n• Tumur-Ochir, Senior ML Engineer@ And Systems Tech LLC",
      description: "Ask Me Anything",
      startTime: "11:40",
    },
    {
      name: "• Munkh-Od, CTO @ DiverseSolutions \n • Tuvshintsenguun, Software Test Engineer @ Input Output Global \n• Bilguun, Senior Software Engineer @ Unimedia Solutions",
      description: "Ask Me Anything",
      startTime: "13:40",
    },
    {
      name: "• Dulmandakh, Founder / CEO @ Khuur Music Group LLC \n• Lkhavuujal, Director of DevOps Division @ Grapecity Mongolia LLC",
      description: "Ask Me Anything",
      startTime: "14:40",
    },
    {
      name: "• Ganbayar, Cyber security researcher  @ University of Oxford \n • Tengis, Information security advisor \n • Bilguun, CTO@ Nomadays LLC",
      description: "Ask Me Anything",
      startTime: "15:40",
    },
    {
      name: "• Monhoo, Indie Game Dev Developer @ Alpha Games \n• Batzorig, PM / VR Dev @ Digital Solutions Mongolia \n• Namuun, PM / VR Dev @ Digital Solutions Mongolia",
      description: "Ask Me Anything",
      startTime: "16:40",
    },
  ],
}
